import React from "react";
import styled from "styled-components";
import { IoOpenOutline } from "react-icons/io5";

import { Container, Wrapper, Row, Box } from "../components/util";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const NewsCard = styled(Row)`
  border: solid 1px #ddd;
  padding: 30px;

  &.-addBorder {
    border-top: solid 8px ${(props) => props.theme.colours.Secondary};
  }

  h2,
  p {
    margin-bottom: 0;
  }

  a {
    display: block;
  }
`;

const news = [
  {
    image: "DrNewsPic.png",
    title: [
      <span>Dr Shahin Ghanoonimahabadi</span>,
      <br />,
      <span className="-fontLight">RecEng DSAP | RecEng PIC</span>,
    ],
    description: [
      <p css={{ marginBottom: "10px !important" }}>
        ABuild are excited to share with you Shahin’s latest achievement of
        becoming a recognised engineer for Dam Safety Assurance and Potential
        Impact Classifications for dams within New Zealand.
      </p>,
      <p>ABuild look forward to assisting New Zealand within this sector.</p>,
    ],
    linkText: "",
    link: "",
  },
  {
    image: "",
    title: "ABuild Makes the News!",
    description:
      "ABuild featured on One News on Monday 1 August 2022 in regard to the slips around the region. Bruce Simms, co-director of ABuild Limited spoke with the journalists and provided some valuable advice.",
    linkText: "Evacuated Lower Hutt residents putting on 'brave face'",
    link:
      "https://www.1news.co.nz/2022/08/01/evacuated-lower-hutt-residents-putting-on-brave-face/",
  },
];

const News = (props) => (
  <Layout>
    <Seo title="Latest News from ABuild" pathname={props.location.pathname} />
    <Container>
      <Wrapper>
        <h1 className="-textCenter">Latest News from ABuild</h1>
        <Box className="-center" gap={20} css={{ maxWidth: "800px" }}>
          {news.map((item, i) => {
            return (
              <NewsCard
                className={item.image ? "-addBorder" : ""}
                alignCenter
                gap={40}
                key={i}
              >
                {item.image && (
                  <Box size={100}>
                    <Image filename={item.image} alt={item.title} rounded />
                  </Box>
                )}
                <Box gap={10}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                  {item.link && (
                    <a href={item.link} target="_blank">
                      {item.linkText}{" "}
                      <IoOpenOutline css={{ top: "2px", marginLeft: "5px" }} />
                    </a>
                  )}
                </Box>
              </NewsCard>
            );
          })}
        </Box>
      </Wrapper>
    </Container>
  </Layout>
);

export default News;
